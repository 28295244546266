const CommonUtils = require("../generic/common_utils");

class LabelFormatDimmedStrategy {
  constructor(modelFormatter) {
    this.modelFormatter = modelFormatter;
  }

  /**
   * Gets a label fit for displaying a record for the user (given the record itself)
   * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
   * @param options {ICustomLabelOptions} The options for formatting the custom label. * @return {string}
   */
  getRecordCustomLabelForDisplay = function(record, options = {}) {
    return this.modelFormatter.getRecordCustomLabelForDisplayDimmed(record, options);
  };

  getRecordCustomLabelForDisplayAlternate = function(record, options) {
    return this.getRecordCustomLabelForDisplay(record, options);
  };

  getRecordCustomLabelForDisplayInReports(record) {
    return record.name;
  }

  getRecordNameFromLabel = function(label, typeCode) {
    let regexRule = new RegExp(`(.*) \\(${typeCode}-\\d+\\)`);

    const match = regexRule.exec(label);
    if (!match) {
      throw new Error(`Cannot extract name from label ${label} for record type ${typeCode}`);
    }

    return match[match.length - 1];
  };

  getRecordKeyFromLabel = function(label) {
    if (!label) {
      return;
    }

    const labelArr = label.split(" (");
    if (labelArr.length === 0) {
      return;
    }

    const lastElement = labelArr[labelArr.length - 1];
    return lastElement.substring(0, lastElement.length -1);
  };

  parseLabel(label) {
    let currentSliced = label && label.split(" (");
    if (currentSliced?.length > 1) {
      const key = currentSliced[1].substring(0, currentSliced[1].length - 1);
      const keyInfo = CommonUtils.parseKey(key);
      const typeCode = keyInfo.typeCode;
      if (keyInfo?.id) {
        currentSliced[1] = `${typeCode}-${keyInfo.id}`;
      } else {
        currentSliced[1] = typeCode;
      }
    }

    return currentSliced;
  }

  getLabelIncludingLastVersionInformation(data) {
    let version = data.lastVersion;
    if (!version) {
      version = data;
    }

    return `${version.name} - ${version.majorVersion}.${version.minorVersion} (${data.typeCode}-${data.id})`;
  }

  getIdNameColumnLabel() {
    return "Name (ID)";
  }

  getBatchAttributeLabel(recordTypeAndId, name) {
    return `${name} (${recordTypeAndId})`;
  }
}

module.exports = {
  LabelFormatDimmedStrategy,
};